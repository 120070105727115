<template>
    <div class="formas-distorsionadas">
        <nav-bar sizetitle="sm" nivel="1"></nav-bar>
        <header-type-game title="Percepción"></header-type-game>
        <tutorial-game 
        v-if="tutorialStatus" 
        audio="tuto" 
        video="https://res.cloudinary.com/juegos-cramer/video/upload/v1617836231/juegos-cramer/tutoriales/nivel1/percepcion/figuras_distorsionadas_j3xls6.mp4"
        text="Señale la forma idéntica a la distorsionada"></tutorial-game>
        <div v-if="!tutorialStatus&&gameRuning">
            <clock-down minuto="1" segundo="0" @setgameruning="setGameRuning()"></clock-down>
            <div class="fd__game-container">
                <div class="fd__game">
                    <div class="fd__main-flower">
                        <svg-flower :color="florPrincipal.color" :numero="florPrincipal.numero" estado="principal"></svg-flower>
                    </div>
                    <div class="fd__alternatives-container">
                        <div 
                            v-for="(item,index) in alternativas"
                            :key="item.id"
                            class="fd__alternative-item"
                            @click="getKeyAns(item.clave,index)"
                        >
                            <svg-flower :color="item.color" :numero="item.numero"></svg-flower>
                            <div v-if="visibleAnswer&&(index_answerSelected==index)" :class="['fd__answer-image',statusAnswer]"></div>
                        </div>
                        <div :class="['fd__capa',capaProtectora]"></div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!gameRuning">
            <results-game nombrejuego="formas distorsionadas" nivel="1" inteligencia="percepcion"></results-game>
        </div>
    </div>
</template>
<script>
import NavBar from '@/components/NavBar'
import HeaderTypeGame from '@/components/HeaderTypeGame'
import TutorialGame from '@/components/TutorialGame.vue'
import ClockDown from '@/components/ClockDown.vue'
import ResultsGame from '@/components/ResultsGame.vue'

import SvgFlower from './SvgFlower'
import {mapMutations,mapState} from 'vuex'
export default {
    components:{
        NavBar,
        HeaderTypeGame,
        TutorialGame,
        ClockDown,
        ResultsGame,
        SvgFlower
    },
    data:()=>({
        puntos:0,
        gameRuning:true,
        florPrincipal:{},
        alternativas:[],
        statusAnswer:'',
        visibleAnswer:true,
        index_answerSelected:0,
        capaProtectora:''
    }),
    computed:{
        ...mapState(['tutorialStatus'])
    },
    methods:{
        ...mapMutations(['changeMenuBarBottom','changeScoreGame','changeScoreDescription','changeTutorialStatus']),
        setGameRuning(){
            this.gameRuning = false
            if(this.puntos<0)
            {
                this.changeScoreGame(0)
                this.changeScoreDescription('insuficiente')
            }
            else{
                this.changeScoreGame(this.puntos)
                if(this.puntos<=200){
                this.changeScoreDescription('insuficiente')
                }
                else if(this.puntos<=400){
                    this.changeScoreDescription('escasa')
                }
                else if(this.puntos<=600){
                    this.changeScoreDescription('suficiente')
                }
                else if(this.puntos<=800){
                    this.changeScoreDescription('buena')
                }
                else{
                    this.changeScoreDescription('optima')
                }
            }
        },
        getKeyAns(key,index){
            this.visibleAnswer = true
            this.index_answerSelected = index
            this.capaProtectora = 'fd__capa-protectora'
            if(key){
                let a = new Audio(require('../../../assets/juegos/audios/acierto.mp3'))
                a.play()
                this.puntos = this.puntos + 50;
                this.statusAnswer = 'fd__correct-answer'
            }
            else{
                let a = new Audio(require('../../../assets/juegos/audios/fallo.mp3'))
                a.play()
                this.puntos = this.puntos - 10;
                this.statusAnswer = 'fd__wrong-answer'
            }

            window.setTimeout(()=>{
                this.capaProtectora=''
                this.gameGenerator()
            },1000)
        },
        getRandom(min, max) {
            return Math.floor(Math.random() * (max - min+1) ) + min;
        },
        getArrayResRandom(min,max,nro){
            //let total = max-min;
            let res = [];
            res.push(nro)
            while(!(res.length==5))
            {
                let aux = this.getRandom(min,max);
                let a = res.find((e)=>e==aux)
                if(!a&&a!=nro){
                    res.push(aux)
                }
            }
            
            return res;
        },
        reordenarArray(arr){
            let a = arr.sort(function() { return Math.random() - 0.5 })
           // console.log(a)
           return a;
        },
        gameGenerator(){
            this.statusAnswer = ''
            this.alternativas=[]
            let figuras = ['amarillo','rojo','azul','verde']

            this.florPrincipal={
                color:figuras[this.getRandom(0,3)],
                numero:this.getRandom(1,5)
            }
            let arr_alternativas = this.getArrayResRandom(1,5,this.florPrincipal.numero)
            for(let i=0;i<5;i++)
            {
                if(arr_alternativas[i]==this.florPrincipal.numero)
                {
                    this.alternativas.push({
                        color:this.florPrincipal.color,
                        numero:arr_alternativas[i],
                        clave:true
                    })

                }
                else{
                    this.alternativas.push({
                        color:this.florPrincipal.color,
                        numero:arr_alternativas[i],
                        clave:false
                    })
                }   
            }
            this.reordenarArray(this.alternativas)
        }
    },
    mounted(){
        this.changeMenuBarBottom(false)
        this.gameGenerator()
    }
}
</script>
<style>
.formas-distorsionadas{
    width:100%;
    height: 100vh;
    background-color: #1F0275;
}
.fd__game-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top:2em
}
.fd__game{
    background-color: #EEEFE4;
    width: 95%;
    height: 50vh;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}
.fd__main-flower{
    width: 95%;
    height: 3em;   
}
.fd__alternatives-container{
    width: 90%;
    height: 30vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    position: relative;
}
.fd__alternative-item{
    padding: 1px;
    width: 6em;
    height: 6em;
    border:solid 2px #a8a5a5;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
    transition: all 0.2s;
}
.fd__alternative-item:hover{
    transform: translateY(-10%);
}
.fd__answer-image{
    position:absolute;
    width: 2em;
    height: 2em;
    top:0px;
    right: 0px;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
}
.fd__correct-answer{
    background-image: url('../../../assets/juegos/comunes/comprobado.svg');
}
.fd__wrong-answer{
    background-image: url('../../../assets/juegos/comunes/cancelar.svg');
}
.fd__capa{
    position: absolute;
}
.fd__capa-protectora{
    width: 100%;
    height: 30vh;
    opacity: 0;
    background-color: red;
}
@media (min-width: 900px) and (orientation: landscape) {
    .fd__game{
        width: 60%;
        }   
    }
</style>