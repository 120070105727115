<template>
        <div :class="[tipo, color+'-'+numero ]">
            
        </div>
</template>
<script>
export default {
    name:'SvgFlower',
    props:['color','numero','estado'],
    data:()=>({
        
    }),
    computed:{
        tipo(){
            if(this.color=='verde'&&this.estado!='principal'){
                return 'svg-sheet-container svg-hoja'
                
            }
            else{
                return 'svg-flower-container'
            }
        }
    }
}
</script>
<style>

.svg-flower-container{
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
}
.svg-sheet-container{
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
}
.svg-hoja{
    background-size: 50% 100%;
}
.amarillo-1{
    background-image: url('../../../assets/juegos/nivel1/percepcion/j3/flor-amarilla-1.svg');
}
.amarillo-2{
    background-image: url('../../../assets/juegos/nivel1/percepcion/j3/flor-amarilla-2.svg');
}
.amarillo-3{
    background-image: url('../../../assets/juegos/nivel1/percepcion/j3/flor-amarilla-3.svg');
}
.amarillo-4{
    background-image: url('../../../assets/juegos/nivel1/percepcion/j3/flor-amarilla-4.svg');
}
.amarillo-5{
    background-image: url('../../../assets/juegos/nivel1/percepcion/j3/flor-amarilla-5.svg');
}

.azul-1{
    background-image: url('../../../assets/juegos/nivel1/percepcion/j3/flor-azul-1.svg');
}
.azul-2{
    background-image: url('../../../assets/juegos/nivel1/percepcion/j3/flor-azul-2.svg');
}
.azul-3{
    background-image: url('../../../assets/juegos/nivel1/percepcion/j3/flor-azul-3.svg');
}
.azul-4{
    background-image: url('../../../assets/juegos/nivel1/percepcion/j3/flor-azul-4.svg');
}
.azul-5{
    background-image: url('../../../assets/juegos/nivel1/percepcion/j3/flor-azul-5.svg');
}

.rojo-1{
    background-image: url('../../../assets/juegos/nivel1/percepcion/j3/flor-roja-1.svg');
}
.rojo-2{
    background-image: url('../../../assets/juegos/nivel1/percepcion/j3/flor-roja-2.svg');
}
.rojo-3{
    background-image: url('../../../assets/juegos/nivel1/percepcion/j3/flor-roja-3.svg');
}
.rojo-4{
    background-image: url('../../../assets/juegos/nivel1/percepcion/j3/flor-roja-4.svg');
}
.rojo-5{
    background-image: url('../../../assets/juegos/nivel1/percepcion/j3/flor-roja-5.svg');
}

.verde-1{
    background-image: url('../../../assets/juegos/nivel1/percepcion/j3/hoja-verde-1.svg');
}
.verde-2{
    background-image: url('../../../assets/juegos/nivel1/percepcion/j3/hoja-verde-2.svg');
}
.verde-3{
    background-image: url('../../../assets/juegos/nivel1/percepcion/j3/hoja-verde-3.svg');
}
.verde-4{
    background-image: url('../../../assets/juegos/nivel1/percepcion/j3/hoja-verde-4.svg');
}
.verde-5{
    background-image: url('../../../assets/juegos/nivel1/percepcion/j3/hoja-verde-5.svg');
}
</style>